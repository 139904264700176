<div class='carousel-holder' *ngIf="carouselData">
    <div class="default-slider" *ngIf="carouselData && carouselData.length && !carouselData[0].type; else itemSlider">
        <ngb-carousel [showNavigationArrows]="true" [showNavigationIndicators]="true">  
            <ng-template ngbSlide *ngFor="let item of carouselData">  
                <img src="{{ item.image }}" alt="slide">
                <span class="title" *ngIf="item.title">{{ item.title }}</span>
                <span class="description" *ngIf="item.description">{{ item.description }}</span>
            </ng-template>
        </ngb-carousel>
    </div>
    <ng-template #itemSlider>
        <div class="customize-slider" [ngClass]="{'full-width': isFullWidth}">
            <ngb-carousel [showNavigationArrows]="showNavigationArrows" [showNavigationIndicators]="true">
                <ng-template ngbSlide *ngFor="let item of carouselData">  
                    <div [ngSwitch]="item.type">
                        <div *ngSwitchCase="'text'" class="item type-text">
                            <p *ngIf="item.description">{{ item.description }}</p>
                        </div>
                        <div *ngSwitchCase="'image'" class="item type-image">
                            <img src="{{ item.image }}" alt="slide">
                        </div>
                        <div *ngSwitchCase="'benefit'" class="item type-benefit">
                            <span class="title" [innerHTML]="item.title"></span>
                            <ul>
                                <li *ngFor="let benefit of item.benefits">
                                    <img src="{{ benefit.image }}" alt="benefit">
                                    <p [innerHTML]="benefit.description"></p>
                                </li>
                            </ul>
                        </div>
                        <div *ngSwitchCase="'club'" class="item type-club">
                            <img class="club-logo" src="{{ item.logo }}" alt="club-logo">
                            <span class="title" [innerHTML]="item.title"></span>
                            <span class="description" [innerHTML]="item.description"></span>
                            <ul>
                                <li *ngFor="let benefit of item.benefits">
                                    <img src="{{ benefit.image }}" alt="benefit">
                                    <p [innerHTML]="benefit.description"></p>
                                </li>
                            </ul>
                            <div class="foot-url" *ngIf="item.link">
                                <a href="item.link.url" target="_blank">{{item.link.title}}</a>
                            </div>
                        </div>
                        <div *ngSwitchCase="'app'" class="item type-app">
                            <img class="app-logo" src="{{ item.logo }}" alt="app-logo">
                            <span class="title" [innerHTML]="item.title"></span>
                            <div class="buttons-holder" [style.background-image]="'url(' + item.background_image + ')'">
                                <p class="description" [innerHTML]="item.description"></p>
                                <ul>
                                    <li *ngFor="let btn of item.buttons">
                                        <a href="{{ btn.url }}"><span class="app-button" [style.background-image]="'url(' + btn.image + ')'"></span></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </ngb-carousel>
        </div>
    </ng-template>
</div> 
