import { AccountState, AccountModel } from './account.state';
import { Selector, createSelector, SelectorOptions } from '@ngxs/store';
import { avatarGenerator, formatDate, initialFormat } from '../../helpers';

export class AccountSelectors {
    @Selector([AccountState])
    @SelectorOptions({
        suppressErrors: true,
    })
    static account(state: AccountModel) {
        if (state?.account) {
            const account = { ...state.account };

            // fallback when user d'not have avatar img
            if (!account?.avatar) {
                account.avatar = {
                    url: avatarGenerator(
                        account.first_name[0] + account.last_name[0]
                    ),
                };
            }
            return account;
        }
        return null;
    }

    @Selector([AccountState])
    static getPhones(state: AccountModel) {
        return state?.account?.phones;
    }

    @Selector([AccountState])
    static names(
        state: AccountModel
    ): { first_name: string; last_name: string; username: string } | null {
        if (state?.account) {
            return {
                first_name: state.account.first_name,
                last_name: state.account.last_name,
                username: state.account.username,
            };
        } else {
            return null;
        }
    }

    @Selector([AccountState])
    static addresses(
        state: AccountModel
    ):
        | Array<{
              address_line_one: string;
              address_line_two: string;
              city: string;
              default: boolean;
              id: number;
              note: string;
              postcode: string;
              sort: number;
              address_formatted?: string;
          }>
        | null
        | [] {
        if (state?.account) {
            return state.account.addresses;
        } else {
            return null;
        }
    }

    @Selector([AccountState])
    static birthdate(state: AccountModel) {
        return state?.account?.birth_date_formatted;
    }

    @Selector([AccountState])
    static avatar(state: AccountModel) {
        // fallback when user d'not have avatar img
        if (state?.account && !state.account.avatar) {
            return avatarGenerator(
                state.account.first_name[0] + state.account.last_name[0]
            );
        }
        return state?.account?.avatar?.url;
    }

    @Selector([AccountState])
    static email(state: AccountModel) {
        return state?.account?.username;
    }

    @Selector([AccountState])
    static preferences(state: AccountModel) {
        return state?.account?.preferences;
    }

    @Selector([AccountState])
    static membership(state: AccountModel) {
        if (state?.account?.membership) {
            const membership = { ...state.account?.membership };

            // membership['valid_to_formatted'] = formatDate(
            //     membership.valid_to,
            //     'll'
            // );

            return membership;
        }

        return null;
    }

    @Selector([AccountSelectors.membership])
    static expiringMembershipAlert(membership) {
        if (membership) {
            const auto_renew_enabled = membership.auto_renew_enabled;
            const reminderOffsetDays = 30;
            const membershipValidTo = initialFormat(membership.valid_to);
            const today = initialFormat();
            const diff = membershipValidTo.diff(today, 'days');

            return {
                shouldRemind: diff < reminderOffsetDays,
                daysLeft: diff,
                auto_renew_enabled
            };
        }

        return null;
    }

    @Selector([AccountState])
    static referralCode(state: AccountModel) {
        return state?.account?.referral_code;
    }

    @Selector([AccountState])
    static getAvailableCredits(state: AccountModel) {
        return state?.account?.credit_formatted;
    }

    @Selector([AccountState])
    static referralStats(state: AccountModel) {
        return state?.referral_stats;
    }

    @Selector([AccountState])
    static deleteAccountRequested(state: AccountModel) {
        return state?.account?.delete_account_requested;
    }

    @Selector([AccountState])
    static paymethods(state: AccountModel) {
        return state?.account?.paymethods;
    }

    @Selector([AccountState])
    static paymentMethods(state: AccountModel) {
        return state?.payment_methods;
    }

    @Selector([AccountState])
    static shortcodes(state: AccountModel) {
        if (state?.account) {
            return {
                first_name: state.account.first_name,
                last_name: state.account.last_name,
                email: state.account.username,
            };
        }

        return null;
    }
}
